import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged, signOut, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import logo from './logo.png';
import './App.css';
import PhoneNumberLogin from './PhoneNumberLogin';
import { FaSun, FaMoon } from 'react-icons/fa';


const LoginForm = ({ onForgotPassword, email, password, setError, setEmail, setPassword, error }) => {

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <form className="login-form" onSubmit={handleLogin}>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Login</button>
      {error && <p className="error">{error}</p>}
    </form>
  );
};


function SuccessPage({ onSwitchToLogin }) {
  return (
    <div className="success-page">
      <div className="success-container">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Registration Successful!</h1>
        <p>Please check your email to verify your account.</p>
        <button onClick={onSwitchToLogin}>Back to Login</button>
      </div>
    </div>
  );
}

function HomePage({ user }) {
  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <div className="home-page">
      <div className="home-container">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Welcome to Peace Map, {user.displayName}!</h1>
        <button>Create an Event</button>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </div>
  );
}


function AuthScreen() {
  const [isRegistered, setIsRegistered] = useState(false);
  const [user, setUser] = useState(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent!');
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  if (user) {
    return <HomePage user={user} />;
  }

  if (isRegistered) {
    return <SuccessPage onSwitchToLogin={() => setIsRegistered(false)} />;
  }

  return (
    <div className="login-screen">
      <div className="login-container">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Login</h1>
        <div className="login-form-container">
          {!isPhoneLogin && <LoginForm error={error} email={email} password={password} setError={setError} setEmail={setEmail} setPassword={setPassword} onForgotPassword={handleForgotPassword}  />}
          {isPhoneLogin && <PhoneNumberLogin />}
        </div>
        <button type="button" className="phone-button" onClick={() => setIsPhoneLogin(true)}>Login with Phone</button>
        <button className="show-more-options" onClick={() => setShowMoreOptions(!showMoreOptions)}>
          {showMoreOptions ? 'Show Less Options' : 'Show More Options'}
        </button>
        <div className={`more-login-options ${showMoreOptions ? 'show' : ''}`}>
          <button type="button" className="forgot-password-button" onClick={handleForgotPassword}>Forgot Password</button>
          <button type="button" onClick={() => setIsRegistered(true)}>Register</button>
        </div>
        <button className="dark-mode-toggle" onClick={() => setIsDarkMode(!isDarkMode)}>
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </button>

      </div>
    </div>
  );
}

function App() {
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    const checkIfMobile = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  return (
    <div className="App">
      <AuthScreen />
      {isInstallable && isMobile && (
        <button onClick={handleInstallClick} className="install-button">
          Install App
        </button>
      )}
    </div>
  );
}

export default App;
