import React, { useState } from 'react';
import { auth } from './firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const PhoneNumberLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(null);
  const [confirmationResult, setConfirmationResult] = useState(null);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };

  const handleStartVerification = async () => {
    setError(null);
    try {
      const appVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("reCAPTCHA solved");
        }
      }, auth);
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setConfirmationResult(confirmation);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleVerifyCode = async () => {
    setError(null);
    try {
      await confirmationResult.confirm(verificationCode);
      // Successful login
      console.log('Phone number verified successfully!');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h1>Login with Phone Number</h1>
      <div id="recaptcha-container"></div>
      {error && <p className="error">{error}</p>}
      <input
        type="tel"
        placeholder="Phone Number"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        required
      />
      <button onClick={handleStartVerification}>Start Verification</button>
      {confirmationResult && (
        <>
          <input
            type="text"
            placeholder="Verification Code"
            value={verificationCode}
            onChange={handleVerificationCodeChange}
            required
          />
          <button onClick={handleVerifyCode}>Verify Code</button>
        </>
      )}
    </div>
  );
};

export default PhoneNumberLogin;
