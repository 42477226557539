import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCO0VSBlOof233m6DTUjDhFv0i066aRH9s",
    authDomain: "peace-map-44ecf.firebaseapp.com",
    projectId: "peace-map-44ecf",
    storageBucket: "peace-map-44ecf.appspot.com",
    messagingSenderId: "518197701314",
    appId: "1:518197701314:web:aef55dee776a92f03a295b",
    measurementId: "G-6F2FTCKBCE"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
